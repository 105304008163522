import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"



const Services = ({ data }) => {
    const {markdownRemark} = data
    const title = markdownRemark.frontmatter.title
    const heading = markdownRemark.frontmatter.heading
    const image = markdownRemark.frontmatter.image.childImageSharp.fluid
    const html = markdownRemark.frontmatter.html
    return(
  <Layout>
    <SEO title="Services" />
     <div className  ="subnav">
            <nav role="navigation">
                <ul>
                    <li className="nav-lvl"><a href="#" aria-haspopup="true">Sistemas de Gestión</a>
                        <ul className="dropdown" aria-label="submenu">
                            <li><Link to="/servicios/sistemas-de-gestion-calidad" activeClassName="active">Gestión de Calidad</Link></li>
                            <li><Link to="/servicios/sistemas-de-gestion-ambiental" activeClassName="active">Gestión Ambiental</Link></li>
                            <li><Link to="/servicios/sistemas-de-gestion-seguridad-salud" activeClassName="active">Gestión de Seguridad y Salud en el trabajo</Link></li>
                            <li><Link to="/servicios/sistemas-de-gestion-continuidad-negocio" activeClassName="active">Gestión de Continuidad de Negocio</Link></li>
                            <li><Link to="/servicios/sistemas-de-gestion-riesgos" activeClassName="active">Gestión de Riesgos</Link></li>
                            <li><Link to="/servicios/sistemas-de-gestion-seguridad-informacion" activeClassName="active">Gestión de Seguridad de la Información</Link></li>
                            <li><Link to="/servicios/sistemas-de-gestion-antisoborno" activeClassName="active">Gestión Antisoborno</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/servicios/entrenamiento-coorporativo" activeClassName="active">Entrenamiento Coorporativo</Link></li>
                    <li><Link to="/servicios/auditorias-internas" activeClassName="active">Auditorías Internas</Link></li>
                    <li><Link to="/servicios/asesoria-legal" activeClassName="active">Asesoría Legal</Link></li>
                </ul>
            </nav>
        </div>
    <section className="main">    
        
        <div className="container">
            <div className="heading">
                <h1>{title}</h1>
                <h3>{heading}</h3> 
                <div className="image">
                    <Img fluid={image}  />
                </div> 
            </div>
            <div className="text" dangerouslySetInnerHTML={{__html: html}}/>
        </div>
    </section>
    
  </Layout>
)}

export const query = graphql`
    query($pathSlug: String!) {
        markdownRemark(frontmatter: {path: {eq: $pathSlug} }){
            html
            frontmatter{
                title
                heading
                subheading
                html
                image {
                    childImageSharp{
                      fluid(maxWidth:2000){
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }
`


export default Services